<template>
    <div class="user flex main other_main">
        <asiden :asideData="asideList"></asiden>
      <div class="main_r">
        <router-view></router-view>
      </div>
    </div>
</template>

<script>
    import asiden from "../layout/aside/aside.vue"
    export default {
        components: { asiden },
        name: '',
        data(){
            return {
                asideList:[
                    {
                        title:"用户列表",
                        icon:"",
                        router:"/user/userlist"
                    },
                    {
                        title:"新增用户",
                        icon:"",
                        router:"/user/adduser"
                    },
                    {
                        title:"配置管理",
                        icon:"",
                        router:"/user/config"
                    }
                ]
            }
        }
    }
</script>

<style scoped>

</style>